var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"express"},[_c('banner',{attrs:{"subtitle":_vm.subtitle,"maintitle":_vm.maintitle,"logoFlag":_vm.logoFlag,"imgSrc":_vm.imgSrc}}),_c('div',{staticClass:"express_cont"},[_c('div',{directives:[{name:"class",rawName:"v-class",value:('cont animate__animated animate__fadeInup animate__block'),expression:"'cont animate__animated animate__fadeInup animate__block'"}],staticClass:"cont"},[_c('partTitle',{attrs:{"title":"关于我们","subtitle":"About Us"}}),_c('div',{staticClass:"vo_introduce"},[_c('div',{directives:[{name:"class",rawName:"v-class",value:(
            'img_bg animate__animated animate__fadeInup animate__block'
          ),expression:"\n            'img_bg animate__animated animate__fadeInup animate__block'\n          "}],staticClass:"img_bg"},[_c('img',{attrs:{"src":require("../../../static/images/vo/about.png"),"alt":""}})]),_c('p',{directives:[{name:"class",rawName:"v-class",value:('animate__animated animate__fadeInup animate__block'),expression:"'animate__animated animate__fadeInup animate__block'"}]},[_vm._v(" 语音的士，是结合老年人对智慧手机操作的种种不便，对打车进行简化操作、优化流程、深化体验，构建便捷高效的老年人数字出行生活生态模式。 ")]),_c('p',{directives:[{name:"class",rawName:"v-class",value:('animate__animated animate__fadeInup animate__block'),expression:"'animate__animated animate__fadeInup animate__block'"}]},[_vm._v(" 其中，以出租车为例，作为大众公共交通重要载具，深入到各年龄层段人们的生活之中，将出租车数字化服务进行革新，是对老年人数字化生活关爱和倚重的重要途径和举措。 ")]),_c('p',{directives:[{name:"class",rawName:"v-class",value:('animate__animated animate__fadeInup animate__block'),expression:"'animate__animated animate__fadeInup animate__block'"}]},[_vm._v(" 所以语音的士应运而生。 ")])])],1),_c('div',{directives:[{name:"class",rawName:"v-class",value:('cont animate__animated animate__fadeInup animate__block'),expression:"'cont animate__animated animate__fadeInup animate__block'"}],staticClass:"cont"},[_c('partTitle',{attrs:{"title":"我们的产品","subtitle":"Product"}}),_c('span',{directives:[{name:"class",rawName:"v-class",value:(
          'produce_span animate__animated animate__fadeInup animate__block'
        ),expression:"\n          'produce_span animate__animated animate__fadeInup animate__block'\n        "}],staticClass:"produce_span"},[_vm._v("语音的士")]),_c('div',{directives:[{name:"class",rawName:"v-class",value:(
          'produce_div animate__animated animate__fadeInup animate__block'
        ),expression:"\n          'produce_div animate__animated animate__fadeInup animate__block'\n        "}],staticClass:"produce_div"},[_c('swiper',{staticClass:"swiper second",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.swiper),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"advantage-swiper_item"},[_c('div',{staticClass:"bg"},[_c('img',{staticClass:"advantage-swiper_img",attrs:{"src":item.icon}})]),_c('div',{staticClass:"advantage-swiper_bottom"},[_c('p',[_vm._v(_vm._s(item.mome))])])])])}),1),_c('div',{staticClass:"swiper-pagination"})],1),_c('span',{directives:[{name:"class",rawName:"v-class",value:(
          'produce_span animate__animated animate__fadeInup animate__block'
        ),expression:"\n          'produce_span animate__animated animate__fadeInup animate__block'\n        "}],staticClass:"produce_span"},[_vm._v("的士之家")]),_c('div',{directives:[{name:"class",rawName:"v-class",value:(
          'produce_div animate__animated animate__fadeInup animate__block'
        ),expression:"\n          'produce_div animate__animated animate__fadeInup animate__block'\n        "}],staticClass:"produce_div"},[_c('swiper',{staticClass:"swiper third",attrs:{"options":_vm.swiperOption1}},_vm._l((_vm.swiper1),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"advantage-swiper_item"},[_c('div',{staticClass:"bg"},[_c('img',{staticClass:"advantage-swiper_img",attrs:{"src":item.icon}})]),_c('div',{staticClass:"advantage-swiper_bottom"},[_c('p',[_vm._v(_vm._s(item.mome))])])])])}),1),_c('div',{staticClass:"swiper-pagination1"})],1)],1)]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }