<template>
  <div class="express">
    <!-- 头部banner -->
    <banner
      :subtitle="subtitle"
      :maintitle="maintitle"
      :logoFlag="logoFlag"
      :imgSrc="imgSrc"
    ></banner>
    <!-- 主体内容 -->
    <div class="express_cont">
      <div
        class="cont"
        v-class="'cont animate__animated animate__fadeInup animate__block'"
      >
        <partTitle title="关于我们" subtitle="About Us"></partTitle>
        <div class="vo_introduce">
          <div
            class="img_bg"
            v-class="
              'img_bg animate__animated animate__fadeInup animate__block'
            "
          >
            <img src="../../../static/images/vo/about.png" alt="" />
          </div>
          <p v-class="'animate__animated animate__fadeInup animate__block'">
            语音的士，是结合老年人对智慧手机操作的种种不便，对打车进行简化操作、优化流程、深化体验，构建便捷高效的老年人数字出行生活生态模式。
          </p>
          <p v-class="'animate__animated animate__fadeInup animate__block'">
            其中，以出租车为例，作为大众公共交通重要载具，深入到各年龄层段人们的生活之中，将出租车数字化服务进行革新，是对老年人数字化生活关爱和倚重的重要途径和举措。
          </p>
          <p v-class="'animate__animated animate__fadeInup animate__block'">
            所以语音的士应运而生。
          </p>
        </div>
      </div>
      <div
        class="cont"
        v-class="'cont animate__animated animate__fadeInup animate__block'"
      >
        <partTitle title="我们的产品" subtitle="Product"></partTitle>
        <span
          class="produce_span"
          v-class="
            'produce_span animate__animated animate__fadeInup animate__block'
          "
          >语音的士</span
        >
        <div
          class="produce_div"
          v-class="
            'produce_div animate__animated animate__fadeInup animate__block'
          "
        >
          <swiper class="swiper second" :options="swiperOption">
            <swiper-slide v-for="(item, index) in swiper" :key="index">
              <div class="advantage-swiper_item">
                <div class="bg">
                  <img :src="item.icon" class="advantage-swiper_img" />
                </div>
                <div class="advantage-swiper_bottom">
                  <p>{{ item.mome }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
        <span
          class="produce_span"
          v-class="
            'produce_span animate__animated animate__fadeInup animate__block'
          "
          >的士之家</span
        >
        <div
          class="produce_div"
          v-class="
            'produce_div animate__animated animate__fadeInup animate__block'
          "
        >
          <swiper class="swiper third" :options="swiperOption1">
            <swiper-slide v-for="(item, index) in swiper1" :key="index">
              <div class="advantage-swiper_item">
                <div class="bg">
                  <img :src="item.icon" class="advantage-swiper_img" />
                </div>
                <div class="advantage-swiper_bottom">
                  <p>{{ item.mome }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination1"></div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        pagination: ".swiper-pagination",
        paginationClickable: true,
        loop: true,
      },
      swiperOption1: {
        pagination: ".swiper-pagination1",
        paginationClickable: true,
        loop: true,
      },
      logoFlag: false,
      maintitle: "语音的士/的士之家",
      subtitle: "自由工作·更高收入",
      imgSrc: require("@/assets/images/vo-bg.jpg"),
      flag: false,
      swiper: [
        {
          icon: require("../../../static/images/vo/yu-one.png"),
          mome: "老年人也可以使用的打车平台",
        },
        {
          icon: require("../../../static/images/vo/yu-two.png"),
          mome: "语音播报，指令交互",
        },
        {
          icon: require("../../../static/images/vo/yu-three.png"),
          mome: "一键呼叫打车，简化打车步骤",
        },
      ],
      swiper1: [
        {
          icon: require("../../../static/images/vo/ds-one.png"),
          mome: "为出租车司机提供线上接单平台",
        },
        {
          icon: require("../../../static/images/vo/ds-two.png"),
          mome: "简便接单流程，快速接单",
        },
        {
          icon: require("../../../static/images/vo/ds-three.png"),
          mome: "巡游，线上接单两不误",
        },
      ],
    };
  },
  mounted() {
    this.routerTo(5);
  },
};
</script>

<style>
.mobile_swiper {
  position: relative;
}
.swiper .advantage-swiper_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.43rem;
  color: #2d2d39;
}
.swiper .advantage-swiper_item .bg {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.48rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom p {
  font-size: 0.24rem;
  margin-top: 0.47rem;
}
.swiper-pagination,
.swiper-pagination1,
.swiper-pagination2 {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0.45rem auto;
}
.swiper-pagination .swiper-pagination-bullet,
.swiper-pagination1 .swiper-pagination-bullet,
.swiper-pagination2 .swiper-pagination-bullet {
  margin: 0 0.1rem;
  background: #cbcbcb;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #676767 !important;
  opacity: 1;
}
</style>
<style scoped>
.animate__block {
  visibility: visible !important;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
.animate__fadeInup {
  -webkit-animation-name: FadeInup;
  animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: 0.5s;
}
.express_cont {
  margin-bottom: 2rem;
}
.express .express_cont .cont {
  margin-top: 0.9rem;
}
.cont .vo_introduce {
  margin-top: 0.44rem;
}
.cont .vo_introduce .img_bg {
  width: calc(100% - 0.96rem);
  height: 3.37rem;
  background: #f4f4f4;
  margin-left: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}
.cont .vo_introduce .img_bg img {
  width: 1.53rem;
}
.cont .vo_introduce p {
  font-size: 0.24rem;
  padding: 0.44rem 0.48rem 0;
  visibility: hidden;
}
.cont span {
  display: block;
  font-size: 0.36rem;
  font-weight: bold;
  margin-left: 0.48rem;
  margin-top: 0.48rem;
}
.first .bg,
.first .bg img {
  width: 5.4rem;
  height: 3.1rem;
}
.second .bg,
.third .bg {
  width: 6.56rem;
  height: 3.07rem;
}
.second .bg img,
.third .bg img {
  height: 2.19rem;
}
.produce_span,
.produce_div {
  visibility: hidden;
}
@keyframes FadeInup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>